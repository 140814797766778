/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { gsap } from 'gsap'
import { GlobalIntro } from './animations/global-intro'

import Form from './modules/form'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {
  const { body } = domStorage

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)

  GlobalIntro()

  // Newsletter Form
  if (domStorage.newsletterForm) viewStorage.newsletterFormModule = new Form(domStorage.newsletterForm)
}

export const onEnter = (to, trigger) => {
  // LoadingClasses
  const { body } = domStorage

  body.classList.remove('--loading')

  // Update Current View
  viewStorage.current = to.renderer.content

  if (viewStorage.viewScroll.scroll) viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()
  viewStorage.viewModals = new viewModals()

  // Newsletter Form
  domStorage.newsletterForm = document.getElementById('NewsletterForm')
  if (domStorage.newsletterForm) viewStorage.newsletterFormModule = new Form(domStorage.newsletterForm)
}

export const onEnterCompleted = (to, from, trigger) => {
  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }

}

export const onLeave = (from, trigger) => {
  // Remove Previous Page Effects
  const { hasInView, hasPrllx } = viewStorage

  // Stop scroll
  viewStorage.viewScroll.scroll.stop()

  hasInView && viewStorage.viewInView.destroy()
  hasPrllx && viewStorage.viewPrllx.destroy()

  // Destroy Newsletter Form
  if (viewStorage.newsletterFormModule) {
    viewStorage.newsletterFormModule.destroy()
    domStorage.newsletterForm = null
    viewStorage.newsletterFormModule = null
  }

  // LoadingClasses
  domStorage.body.classList.add('--loading')
  domStorage.body.classList.add('--animating')

  // Close Menu
  globalStorage.menuOpen && globalStorage.closeMobileMenu()
  globalStorage.closeMobileMenu()
}
