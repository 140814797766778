/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageHome from './pages/page-home'
import PageCareers from './pages/page-careers'
import PageInvestors from './pages/page-investors'
import PageAboutUs from './pages/page-about-us'
import PageContact from './pages/page-contact'
import SinglePost from './pages/single-post'
import SingleStudy from './pages/single-study'
import SingleProduct from './pages/single-product'
import ArchivePublications from './pages/archive-publications'
import ArchivePosts from './pages/archive-posts'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item):not(.wpml-ls-link)',
  // removeOldContent: false,
  renderers: {
    default: Page,
    pageHome: PageHome,
    pageCareers: PageCareers,
    pageInvestors: PageInvestors,
    pageAboutUs: PageAboutUs,
    pageContact: PageContact,
    singlePost: SinglePost,
    singleStudy: SingleStudy,
    singleProduct: SingleProduct,
    archivePublications: ArchivePublications,
    archivePosts: ArchivePosts
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
