import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { Flip } from 'gsap/Flip'
gsap.registerPlugin(ScrollTrigger, SplitText, Flip)

export const pageIntro = (delay = 0.5) => {
  const { current } = viewStorage

  const generalHeader = current.children[0]
  const allSections = [...current.children].slice(1)

  const background = generalHeader.querySelector('.clip > .visual__container')
  const title = generalHeader.querySelector('h1')
  const button = generalHeader.querySelector('.wrapper > .Btn')
  const formattedTitle = new SplitText(title, { type: 'lines' })
  const breadcrumb = generalHeader.querySelector('.Breadcrumb')
  const jobOfferInfos = generalHeader.querySelector('.infos')

  const productSubtitle = generalHeader.querySelector('.product-subtitle')
  const productVideoButton = generalHeader.querySelector('.product-video-button')

  const tl = gsap
    .timeline({
      delay,
      defaults: { ease: 'power3', duration: 1 },
      onStart: () => {
        // Reset scroll
        setTimeout(() => { window.scrollTo(0, 0) }, 100)
      },
      onComplete: () => {
        domStorage.body.classList.remove('--animating')

        // Single Product
         if (domStorage.percentage) domStorage.percentage.remove()

        // Clean styles
        formattedTitle && formattedTitle.revert()
        generalHeader && gsap.set(generalHeader, { clearProps: 'opacity' })
        allSections && allSections.length && gsap.set(allSections, { clearProps: 'all' })
        button && gsap.set(button, { clearProps: 'opacity' })
        breadcrumb && gsap.set(breadcrumb, { clearProps: 'opacity' })

        // Page 404
        if (current.className === 'page404') {
          gsap.set(current.querySelector('p'), { clearProps: 'all' })
          gsap.set(current.querySelector('.Btn'), { clearProps: 'all' })
        }
      }
    })

  generalHeader && tl.from(generalHeader, { opacity: 0, duration: 1.2, ease: 'power2.inOut' }, 0)
  // Background
  background && tl.from(background, { scale: 1.2, rotate: -2, duration: 1.2, ease: 'power2.inOut' }, 0)
  // Title h1  
  formattedTitle && formattedTitle.lines.length && tl.fromTo(formattedTitle.lines, { yPercent: 50, rotate: 2, opacity: 0 }, { yPercent: 0, rotate: 0, opacity: 1, transformOrigin: '0% 100%', stagger: 0.1 }, 0.75)
  // Button
  button && tl.fromTo(button, { opacity: 0 }, { opacity: 1, ease: 'power2.inOut' }, '-=0.5')
  // Breadcrumb
  breadcrumb && tl.fromTo(breadcrumb, { opacity: 0 }, { opacity: 1, ease: 'power2.inOut' }, '-=0.5')
  // Job Infos
  jobOfferInfos && tl.fromTo(jobOfferInfos, { opacity: 0 }, { opacity: 1, ease: 'power2.inOut' })
  // All sections
  allSections && allSections.length && tl.from(allSections, { y: 20, opacity: 0 }, 0.85)
  // Single Product
  productSubtitle && tl.fromTo(productSubtitle, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'power2.inOut' }, 0.2)
  productVideoButton && tl.fromTo(productVideoButton, { opacity: 0 }, { opacity: 1, ease: 'power2.inOut' }, '-=0.5')

  // Page 404
  if (current.className === 'page404') {
    tl.fromTo(current.querySelector('p'), { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'power2.out' }, '-=0.75')
    tl.fromTo(current.querySelector('.Btn'), { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'power2.out' }, '-=0.75')
  }

  // Single Product
  if (domStorage.percentage) tl.to(domStorage.percentage, { opacity: 0 }, 0)

  return tl
}
